import { fetchWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';
import { orgAuthURL } from '../../utils/functions';

export const postAuthApiUrl = options => {
    const { apiBasePath, authPath } = endpoints;
    const url = `${apiBasePath}${authPath}`;
    return fetchWebApi(url, options, '', true).request;
};

export const getRefreshTokenApiUrl = token => {
    const { apiBasePath, refreshTokenPath } = endpoints;
    const url = `${apiBasePath}${refreshTokenPath}`;
    return fetchWebApi(url, token).request;
};

export const getRefreshOrgTokenApiUrl = (token, userId) => {
    const { basePath = '' } = orgAuthURL();
    const url = `${basePath}/api/v2/users/${userId}`;
    return fetchWebApi(url, token).request;
};

export const getPrivilegesFromOrgApiUrl = (token, userId) => {
    const { basePath = '' } = orgAuthURL();
    const url = `${basePath}/api/v2/users/${userId}?include=privileges,property_privileges.property`;
    return fetchWebApi(url, token).request;
};

export const getClientPrivilegesFromOrgApiUrl = (token, clientId) => {
    const { basePath = '' } = orgAuthURL();
    const url = `${basePath}/api/v2/clients/${clientId}`;
    return fetchWebApi(url, token).request;
};

export const getClientInfo = options => {
    const { apiBasePath, clientPath } = endpoints;
    const url = `${apiBasePath}${clientPath}${options}`;
    return fetchWebApi(url).request;
};

export const getPhaseInfo = cliendId => {
    const { apiBasePath, phasePath } = endpoints;
    const url = `${apiBasePath}${phasePath}/${cliendId}/phases`;
    return fetchWebApi(url).request;
};

export const getCurrentClientDetails = (clientId, token) => {
    const { apiAnalyticsFilterBasePath, clientDetails } = endpoints;
    const url = `${apiAnalyticsFilterBasePath}${clientDetails(clientId)}`;
    return fetchWebApi(url, token).request;
};
